import React from "react";
import { Link } from "react-router-dom";
import FormOne from "../contact/FormOne";

const BannerFive = () => {
  return (
    <div
      className="banner banner-style-5"
      style={{
        backgroundImage: `url(${
          process.env.PUBLIC_URL + "/images/banner/talkwithgurubanner2.jpg"
        })`,
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-xl-7">
            <div className="banner-content">
              <h1 className="title">
                Get instant Money For Just Referring a Friend, Earn 10-15% on
                every deal
              </h1>
              <div>
                <a
                  href={"https://wa.me/message/T6IIQTJ7RB5WH1"}
                  target="_blank"
                  className="axil-btn btn-fill-white btn-large"
                >
                  Connect Now
                </a>
              </div>
            </div>
          </div>
          {/* <div className="col-lg-6 col-xl-5">
                        <div className="banner-form">
                            <div className="contact-form-box shadow-box">
                                <h3 className="title">Connect with us</h3>
                                <FormOne />
                            </div>
                        </div>
                    </div> */}
        </div>
      </div>
    </div>
  );
};

export default BannerFive;
