import React from "react";
// import "./sstyle.css";

const PricingCalculator = () => {
  return (
    <div>
      <section className="page-calculator" style={{ marginTop: "40px" }}>
        {/* <section className="page-calculator"> */}
        <div className="wrap">
          <div
            className="component calculator home-calculator"
            data-test="widget-get-price"
          >
            <button className="open-widget">hi</button>
            <button className="close-widget">
              <img
                // src="https://writinguniverse.com/assets/img/content/cross.svg"
                alt=""
              />
            </button>
            <div className="component_wrapper">
              <div className="calculator_title">Calculate the Price</div>
              <input
                id="new_member_discount"
                type="checkbox"
                value="15OFF"
                checked="checked"
                style={{ display: "none" }}
              />
              <div className="calculator_content">
                <div className="calculator_left_block">
                  <div className="calculator_block">
                    <div
                      className="f_group type_of_service service"
                      data-test="service"
                    >
                      <div className="f_label">
                        <label>Type of service</label>
                      </div>
                      <div className="f_select">
                        <select
                          className="select"
                          style={{
                            width: "inherit",
                            height: "inherit",
                            padding: "0 6px",
                            border: "2px solid #e5e8eb",
                          }}
                          name="service"
                        >
                          <option value="1">Academic paper writing</option>
                          <option value="8">Dissertation services</option>
                          <option value="60">Writing incl. calculations</option>
                          <option value="7">Admission Services</option>
                          <option value="3">
                            Math/Physic/Economic/Statistic Problems
                          </option>
                          <option value="11">Multiple Choice Questions</option>
                          <option value="5">Editing</option>
                          <option value="4">Proofreading</option>
                          <option value="2">Rewriting</option>
                          <option value="6">Copywriting</option>
                          <option value="9">Resume/CV services</option>
                        </select>
                      </div>
                    </div>
                    <div
                      className="f_group type_of_paper assignment-type"
                      data-test="assignment-type"
                    >
                      <div className="f_label">
                        <label for="assignment_type">
                          Type of <span>paper</span>
                        </label>
                      </div>
                      <div className="f_select">
                        {/* <div className="jq-selectbox jqselect select"> */}
                        <select
                          className="select"
                          style={{
                            width: "inherit",
                            height: "inherit",
                            padding: "0 6px",
                            border: "2px solid #e5e8eb",
                          }}
                          name="assignment_type"
                        >
                          <option value="1">Essay</option>
                          <option value="92">Blog Articles</option>
                          <option value="93">Social Media Posts</option>
                          <option value="94">
                            Newsletters &amp; Email Campaigns
                          </option>
                          <option value="95">Landing Page Copy</option>
                          <option value="96">Other Media Content</option>
                          <option value="97">Blog Posts</option>
                          <option value="98">Social Media Content</option>
                          <option value="99">Research Articles</option>
                          <option value="100">
                            Copywriting for Landing Pages
                          </option>
                          <option value="101">
                            Other Types of Media Content
                          </option>
                          <option value="104">Professional Research</option>
                          <option value="105">Custom Research Services</option>
                          <option value="106">Knowledge Base</option>
                          <option value="107">Data Research</option>
                          <option value="108">Project Research Services</option>
                          <option value="6">Coursework</option>
                          <option value="2">Research Paper</option>
                          <option value="3">Term Paper</option>
                          <option value="24">Case study</option>
                          <option value="89">Capstone Project</option>
                          <option value="110">Assignment </option>
                          <option value="13">Book report</option>
                          <option value="50">Report</option>
                          <option value="90">Outline</option>
                          <option value="43">Book review</option>
                          <option value="111">Summary</option>
                          <option value="14">Movie review</option>
                          <option value="15">Research summary</option>
                          <option value="84">Critical essay</option>
                          <option value="5">Dissertation</option>
                          <option value="51">Literature review</option>
                          <option value="85">Reflective writing</option>
                          <option value="4">Thesis</option>
                          <option value="16">
                            Dissertation/Thesis proposal
                          </option>
                          <option value="102">Discussion board post</option>
                          <option value="17">
                            Dissertation Chapter - Abstract
                          </option>
                          <option value="18">
                            Dissertation Chapter - Introduction Chapter
                          </option>
                          <option value="19">
                            Dissertation Chapter - Literature review
                          </option>
                          <option value="20">
                            Dissertation Chapter - Methodology{" "}
                          </option>
                          <option value="21">
                            Dissertation Chapter - Results
                          </option>
                          <option value="22">
                            Dissertation Chapter - Discussion
                          </option>
                          <option value="44">Personal statement</option>
                          <option value="42">Admission essay</option>
                          <option value="88">Research proposal</option>
                          <option value="116">Poster</option>
                          <option value="46">Formatting</option>
                          <option value="117">Memo</option>
                          <option value="10">Editing</option>
                          <option value="9">Proofreading</option>
                          <option value="12">Scholarship essay</option>
                          <option value="118">Cover Letter</option>
                          <option value="119">Research proposal</option>
                          <option value="120">Excel Spreadsheet</option>
                          <option value="25">Lab report</option>
                          <option value="121">Excel Spreadsheet</option>
                          <option value="8">Power Point presentation</option>
                          <option value="30">Calculation</option>
                          <option value="31">Proof</option>
                          <option value="32">Research</option>
                          <option value="33">Equation</option>
                          <option value="34">Optimization</option>
                          <option value="35">Math modeling</option>
                          <option value="26">Article</option>
                          <option value="27">Article critique</option>
                          <option value="28">Annotated bibliography</option>
                          <option value="29">Reaction paper</option>
                          <option value="45">
                            Multiple Choice Questions (Time-framed)
                          </option>
                          <option value="23">
                            Multiple Choice Questions (Non-time-framed)
                          </option>
                          <option value="11">Rewriting</option>
                          <option value="36">Press Release</option>
                          <option value="91">Speech</option>
                          <option value="37">Website Content</option>
                          <option value="38">News article</option>
                          <option value="39">Blogpost</option>
                          <option value="40">Website review</option>
                          <option value="41">Product review</option>
                          <option value="47">Statement of purpose</option>
                          <option value="48">Letter of recommendation</option>
                          <option value="49">Undefined</option>
                          <option value="86">Project</option>
                          <option value="113">Proposal</option>
                          <option value="112">Debate</option>
                          <option value="103">Other</option>
                        </select>
                        {/* </div> */}
                      </div>
                    </div>
                    <div
                      className="f_group academic_level academic-level"
                      data-test="academic-level"
                    >
                      <div className="f_label">
                        <label>Academic level</label>
                      </div>
                      <div className="f_select  desktop_visible tablet_visible">
                        <select
                          className="select"
                          style={{
                            width: "inherit",
                            height: "inherit",
                            padding: "0 6px",
                            border: "2px solid #e5e8eb",
                          }}
                          name="academic_level"
                        >
                          <option value="1">High School</option>
                          <option value="2">Freshman (College 1st year)</option>
                          <option value="3">
                            Sophomore (College 2nd year)
                          </option>
                          <option value="4">Junior (College 3rd year)</option>
                          <option value="5">Senior (College 4th year)</option>
                          <option value="14">Associate&#039;s</option>
                          <option value="8">Bachelor&#039;s</option>
                          <option value="6">Master&#039;s</option>
                          <option value="7">Doctoral</option>
                          <option value="9">College</option>
                          <option value="10">Graduate</option>
                          <option value="11">MBA</option>
                          <option value="13">Law</option>
                          <option value="12">Medical</option>
                        </select>
                      </div>
                    </div>
                    <div
                      className="f_group number_of_pages pages"
                      data-test="number-of"
                    >
                      <div className="f_label">
                        <label for="pages">
                          <span
                            className="problems"
                            style={{ display: "none" }}
                          >
                            Number of problems
                          </span>
                          <span
                            className="questions"
                            style={{ display: "none" }}
                          >
                            Number of questions
                          </span>
                          <span className="slides" style={{ display: "none" }}>
                            Number of slides
                          </span>
                          <span
                            className="default dissertation"
                            style={{ display: "inline" }}
                          >
                            Pages
                          </span>
                        </label>
                      </div>
                      <div className="spinner_wrapper">
                        <input
                          name="pages"
                          id="spinEdit"
                          className="spinner"
                          type="text"
                          data-test="pages-or-words"
                          value="1"
                        />
                        <div className="spinedit">
                          <i className="icon-chevron-up plus">+</i>
                          <i className="icon-chevron-down minus">-</i>
                        </div>
                      </div>
                      <span
                        className="info-counter number_of_words"
                        data-test="approximately"
                      >
                        275 words
                      </span>
                    </div>
                    <div className="f_group urgency">
                      <div className="f_label">
                        <label>Urgency</label>
                        <div className="count"></div>
                      </div>
                      <div className="f_select desktop_visible tablet_visible">
                        <select
                          name="urgency"
                          style={{
                            width: "-webkit-fill-available",
                            height: "inherit",
                            padding: "0 6px",
                            border: "2px solid #e5e8eb",
                          }}
                        >
                          <option value="17" data-hours="3">
                            3 hours
                          </option>
                          <option value="13" data-hours="6">
                            6 hours
                          </option>
                          <option value="12" data-hours="8">
                            8 hours
                          </option>
                          <option value="11" data-hours="12">
                            12 hours
                          </option>
                          <option value="10" data-hours="18">
                            18 hours
                          </option>
                          <option value="9" data-hours="24">
                            24 hours
                          </option>
                          <option value="8" data-hours="48">
                            48 hours
                          </option>
                          <option value="7" data-hours="72">
                            3 days
                          </option>
                          <option value="6" data-hours="96">
                            4 days
                          </option>
                          <option value="5" data-hours="120">
                            5 days
                          </option>
                          <option value="4" data-hours="144">
                            6 days
                          </option>
                          <option value="3" data-hours="168">
                            7 days
                          </option>
                          <option value="2" data-hours="240">
                            10 days
                          </option>
                          <option value="1" data-hours="336">
                            14 days
                          </option>
                          <option value="16" data-hours="480">
                            20 days
                          </option>
                          <option value="15" data-hours="720">
                            30 days
                          </option>
                          <option value="14" data-hours="1440">
                            2 months
                          </option>
                        </select>
                        <div className="jq-selectbox_select">
                          {/* <div className="jq-selectbox_select-text">
                            14 days
                          </div>*/}
                          <div className="jq-selectbox_trigger">
                            <div className="jq-selectbox_trigger-arrow"></div>
                          </div>
                        </div>
                      </div>
                      <span className="f_time_title deadline">
                        Aug 16, 2023 at 10:21 AM
                      </span>
                    </div>
                    <div className="f_group discount-label">
                      <span className="label">First order 15%</span>
                    </div>
                  </div>
                </div>
                <div className="calculator_right_block">
                  <div className="total_price">
                    <div>
                      <b>Total Price:</b>
                      <div className="price-wrap">
                        <span data-test="total-price">
                          <sup id="symbol">$</sup>
                          <i id="total">38</i>
                          <i id="cent">.07</i>
                        </span>
                        <span className="old-price">
                          <sup id="old_symbol">$</sup>
                          <i id="old_total">38</i>
                          <i id="old_cent">.07</i>
                        </span>
                        <div className="loading-price">
                          <span>Calculating</span>
                          {/* <img
                             src="https://writinguniverse.com/assets/img/content/ellipsis.svg"
                            alt="ellipsis"
                          /> */}
                        </div>
                      </div>
                    </div>
                    <a
                      className="brown_button proceed_to_order"
                      target="_top"
                      id="order_button"
                      data-test="order-now"
                      href="#"
                    >
                      Hire an expert
                    </a>
                  </div>
                  <div className="price-error-message">
                    This discount is valid only for orders of new customer and
                    with the total more than 25$
                  </div>
                </div>
              </div>
            </div>
          </div>{" "}
        </div>
      </section>
    </div>
  );
};

export default PricingCalculator;
