import assignmentServices from "../assets/images/assignment-services.webp";
// import liveSession from "../assets/live-session.webp";
import projectReport from "../assets/images/project-report-writing.webp";
import labReport from "../assets/images/lab-report.webp";
// import presentationWriting from "../assets/presentation-writing.webp";
import homework from "../assets/images/homework.webp";
import exams from "../assets/images/exams.webp";
import quizzes from "../assets/images/quizzes.webp";
// import programming from "../assets/programming.webp";
// import webDevelopment from "../assets/web-development.webp";
// import appDevelopment from "../assets/app-development.webp";
// import graphicDesigning from "../assets/graphic-designing.webp";
// import gameDevelopment from "../assets/game-development.webp";
// import collegeApplicationEssay from "../assets/college-application-essay.webp";
// import courseWritingService from "../assets/homework.webp";
import caseStudy from "../assets/images/research-proposal.webp";
// import annotatedBibliography from "../assets/college-application-essay.webp";
// import sopWritingService from "../assets/presentation-writing.webp";
import dissertations from "../assets/images/web-development.webp";
// import researchProposalHelp from "../assets/research-proposal.webp";

export const nav = [
  { name: 'About us', href: 'about' },
  { name: 'Services', href: 'services' },
  { name: 'Features', href: 'features' },
  { name: 'Pricing', href: 'pricing' },
  // { name: 'Review', href: 'testimonials' },
  { name: 'Contact us', href: 'contact' },
  { name: 'Blog', href: 'blog' },
  // { name: 'Become A Tutor', href: '#tutor' },
  // { name: 'Admin', href: '#admin' },
];
export const options = [
  // homework
  {
    // seoTitle: "Expert Homework Help & Assistance for Students | Talk With Guru",
    seoTitle: "Best Websites to Help with Homework, Pay Someone To Do My Homework",
    seoDescription:
      "Get top-notch homework help services from experienced tutors. Our reliable assistance ensures academic success. Affordable support for all subjects.",
    name: "Homework Help",
    href: "homework",
    label: "homework",
    bannerTitle: "BEST WEBSITES TO HELP WITH HOMEWORK",
    bannerDesc:
      "Do you have lot's of Homework? Don't worry, Our expert are ready to help you right now.",
    aboutTitle:
      "Website To Pay Someone To Do My Homework",
    aboutImage: homework,
    serviceAboutSectionPara: [
      "The Talk With Guru Provide Pay Only For Homework Service. You can take the help of our professionals to do your homework. Discover the world of Talk With Guru Homework – your go-to destination for unparalleled Homework assistance. Academic pursuits don't always unfold as anticipated, and planning your career path might seem secure, but aligning your academic journey with aspirations poses a significant challenge.",
      "In such circumstances, turning to a professional academic assistance provider proves beneficial. Seeking help not only contributes to improved academic performance but also fosters student growth without the burden of stress. Talk With Guru stands out as one reliable assistance expert, offering comprehensive guidance and support in completing academic tasks.",
      "As an academic support provider, Talk With Guru takes an active initiative to provide Homework assistance, bringing students closer to achieving an A+ grade. Our dedicated team of writing specialists ensures that you are never left alone in challenging situations. Whether you declare 'I need help with my Homework' or seek assistance with tight deadlines, our 24/7 service commitment remains unwavering.",
      "This dedication has earned us a reputation as the premier academic assistance provider globally, solidifying Talk With Guru' position in the EdTech industry. Experience excellence in Homework assistance with Talk With Guru.",
    ],
    whyTitle: "What Website can Help Me with My Homework",
    serviceWhySectionPara: [
      "Many websites offers Homework service, But The Talk With Guru Offers the most affordable & reliable Homework Service. Wondering why you need this particular Homework section? Academic pursuits often present unexpected challenges, and ensuring that your academic journey aligns with your aspirations can be a significant hurdle. Turning to a professional academic assistance provider, like Talk With Guru, proves beneficial in such circumstances.",
      "Seeking help from Talk With Guru goes beyond improving academic performance – it fosters student growth without the burden of stress. Our comprehensive guidance and support in completing academic tasks set us apart as your reliable assistance expert.",
      "Talk With Guru takes an active initiative to provide Homework assistance, bringing you closer to achieving academic excellence. Our dedicated team of specialists ensures you're never left alone in challenging situations. Whether you declare 'I need help with my Homework' or navigate tight deadlines, our 24/7 service commitment remains unwavering.",
      "This dedication has earned us a global reputation as the premier academic assistance provider, solidifying our position in the EdTech industry. Explore why this Homework section is indispensable on your journey to academic success with Talk With Guru Homework.",
    ],
    howWorksTitle: "How To Hire Someone To Do Your Homework",
  },
  // assignment-help
  {
    seoTitle:
      "Paying Someone To do Assignment We're the Websites That Helps with Assignments",
    seoDescription:
      "Overcome academic challenges with professional assignment writing services. We cover all subjects, meet deadlines, & deliver high-quality work. ",
    name: "Assignment Help",
    href: "assignment-help",
    label: "assignment help",
    bannerTitle: "WEBSITES THAT HELP WITH ASSIGNMENTS",
    bannerDesc:
      "The Talk With Guru helps you to Revolutionize your career with online homework!",
    aboutTitle:
      // "Talk With Guru Assignment Service: Your Ultimate Destination for Top-Quality Academic Assignments",
      "Paying Someone To Do Assignment",
    aboutImage: assignmentServices,
    serviceAboutSectionPara: [
      "Talk With Guru offers Pay to Get assignment Done Service. We're your one-stop destination for top-tier Assignment Service, tailored to revolutionize your academic journey. The pursuit of academic excellence doesn't always unfold as expected, presenting challenges that may seem insurmountable. Planning your career path may be secure, but ensuring your academic journey aligns with your aspirations poses a significant challenge.",
      "Talk With Guru, as a professional academic assistance provider, stands ready to turn these challenges into opportunities for growth and success. Our comprehensive guidance and support in completing academic tasks set us apart in the industry. Whether you find yourself saying, 'I need help with writing an assignment' or require assistance with tight deadlines, our 24/7 commitment ensures you're never alone in challenging situations.",
      "Our dedicated team of writing specialists goes beyond traditional support, offering live sessions to bring you closer to achieving that coveted A+ grade. With a global reputation as the premier academic assistance provider, Talk With Guru solidifies its position in the EdTech industry through unwavering dedication to your success.",
    ],
    serviceWhySectionPara: [
      "Wondering why you need this particular section? The academic journey, despite careful planning, often deviates from the expected path. Aligning your educational aspirations with the realities you face can be challenging. This is where a professional academic assistance provider becomes invaluable.",
      "Talk With Guru, as a reliable academic support expert, goes beyond merely improving academic performance. We foster student growth, alleviating the burden of stress that often accompanies academic challenges. Our commitment to comprehensive guidance and support in completing academic tasks sets us apart.",
      "Imagine having access to live sessions that actively contribute to achieving that elusive A+ grade. At Talk With Guru, our dedicated team of writing specialists ensures you're never alone in facing academic challenges. Whether you're declaring 'I need help with writing an assignment' or navigating tight deadlines, our 24/7 service commitment remains steadfast.",
      "This unwavering dedication has earned Talk With Guru a global reputation as the premier academic assistance provider, solidifying our position in the EdTech industry. Discover why this section is indispensable on your journey to academic success.",
    ],
    howWorksTitle: "How To Get Help With Assignments",
  },
  // project-help
  {
    seoTitle:
      "Get Project Report Writing Services by Professionals | Talk With Guru ",
    seoDescription:
      "Get top-notch project report writing services from skilled professionals. We ensure accuracy, quality, and timely delivery for your project documentation needs.",
    name: "Project Help",
    href: "project-help",
    label: "project help",
    bannerDesc:
      "Revolutionize your career with online homework \n help available today!",
    aboutTitle:
      "Crafting Excellence: Talk With Guru Ensures Precision and Quality in Project Report Writing Services",
    aboutImage: projectReport,
    serviceAboutSectionPara: [
      "Welcome to Talk With Guru Project Report Writing – your ultimate destination for top-tier Project Report Writing services. Academic pursuits don't always unfold as anticipated, and planning your career path might seem secure, but aligning your academic journey with aspirations poses a significant challenge.",
      "In such circumstances, turning to a professional academic assistance provider proves beneficial. Seeking help not only contributes to improved academic performance but also fosters student growth without the burden of stress. Talk With Guru, your reliable assistance expert, offers comprehensive guidance and support in completing academic tasks.",
      "As an academic support provider, Talk With Guru takes an active initiative to provide exceptional Project Report Writing services. Our dedicated team of specialists ensures that you are never left alone in challenging situations. Whether you declare 'I need help with my Project Report' or seek assistance with tight deadlines, our 24/7 service commitment remains unwavering.",
      "This dedication has earned us a reputation as the premier academic assistance provider globally, solidifying Talk With Guru' position in the EdTech industry. Experience excellence in Project Report Writing with Talk With Guru.",
    ],
    serviceWhySectionPara: [
      "Wondering why you need this particular Project Report Writing section? Academic pursuits often present unexpected challenges, and ensuring that your academic journey aligns with aspirations can be a significant hurdle. Turning to a professional academic assistance provider, like Talk With Guru, proves beneficial in such circumstances.",
      "Seeking help from Talk With Guru goes beyond improving academic performance – it fosters student growth without the burden of stress. Our comprehensive guidance and support in completing academic tasks set us apart as your reliable assistance expert.",
      "Talk With Guru takes an active initiative to provide Project Report Writing services, bringing you closer to achieving academic excellence. Our dedicated team of specialists ensures you're never left alone in challenging situations. Whether you declare 'I need help with my Project Report' or navigate tight deadlines, our 24/7 service commitment remains unwavering.",
      "This dedication has earned us a global reputation as the premier academic assistance provider, solidifying our position in the EdTech industry. Explore why this Project Report Writing section is indispensable on your journey to academic success with Talk With Guru Project Report Writing.",
    ],
  },
  // exams
  {
    seoTitle: "Online Exam Help In India By Professionals | Talk With Guru",
    seoDescription:
      "Get academic success with our online exam help services. Experienced tutors, reliable support, and top-notch results. Excel in your exams effortlessly with us!",
    name: "Exam Help",
    href: "exams-help",
    label: "exams help",
    bannerDesc:
      "Revolutionize your career with online homework \n help available today!",
    aboutTitle:
      "Empower Your Success: Talk With Guru' Comprehensive Exam Support Services for Academic Excellence",
    aboutImage: exams,
    serviceAboutSectionPara: [
      "Welcome to Talk With Guru Exams – your ultimate destination for top-notch Exam assistance. Academic pursuits don't always unfold as anticipated, and while planning your career path may seem secure, aligning your academic journey with aspirations poses a significant challenge.",
      "In such circumstances, turning to a professional academic assistance provider proves beneficial. Seeking help from Talk With Guru not only contributes to improved academic performance but also fosters student growth without the burden of stress. Talk With Guru stands out as a reliable assistance expert, offering comprehensive guidance and support in completing academic tasks.",
      "As an academic support provider, Talk With Guru takes an active initiative to provide Exam assistance, bringing students closer to achieving an A+ grade. Our dedicated team of writing specialists ensures that you are never left alone in challenging situations. Whether you declare 'I need help with my Exams' or seek assistance with tight deadlines, our 24/7 service commitment remains unwavering.",
      "This dedication has earned us a reputation as the premier academic assistance provider globally, solidifying Talk With Guru' position in the EdTech industry. Experience excellence in Exam assistance with Talk With Guru.",
    ],
    serviceWhySectionPara: [
      "Curious about why you need this particular Exams section? Academic pursuits often present unexpected challenges, and ensuring that your academic journey aligns with aspirations can be a significant hurdle. Turning to a professional academic assistance provider, like Talk With Guru, proves beneficial in such circumstances.",
      "Seeking help from Talk With Guru goes beyond improving academic performance – it fosters student growth without the burden of stress. Our comprehensive guidance and support in completing academic tasks set us apart as your reliable assistance expert.",
      "Talk With Guru takes an active initiative to provide Exam assistance, bringing you closer to achieving academic excellence. Our dedicated team of specialists ensures you're never left alone in challenging situations. Whether you declare 'I need help with my Exams' or navigate tight deadlines, our 24/7 service commitment remains unwavering.",
      "This dedication has earned us a global reputation as the premier academic assistance provider, solidifying our position in the EdTech industry. Explore why this Exams section is indispensable on your journey to academic success with Talk With Guru Exams.",
    ],
  },
  // quizzes
  {
    seoTitle:
      "Online Quizzes Help Services for Academic Success | Talk With Guru",
    seoDescription:
      "Boost your grades with our online quizzes help services. Get expert assistance for quizzes in any subject. Achieve academic excellence effortlessly!",
    name: "Quiz Help",
    href: "quizzes-help",
    label: "quizzes help",
    bannerDesc:
      "Revolutionize your career with online homework \n help available today!",
    aboutTitle:
      "Quiz Mastery Unleashed: Elevate Your Learning with Talk With Guru' Dynamic Quiz Support Services",
    aboutImage: quizzes,
    serviceAboutSectionPara: [
      "Welcome to Talk With Guru Quizzes – your ultimate destination for top-quality Quiz assistance. Do academic pursuits always unfold as anticipated? More often than not, the answer is a resounding No. While planning your career path may seem secure, ensuring that your academic journey aligns with those aspirations poses a significant challenge.",
      "In such circumstances, turning to a professional academic assistance provider proves beneficial. Seeking help from Talk With Guru not only contributes to improved academic performance but also fosters student growth without the burden of stress. Talk With Guru stands out as a reliable assistance expert, offering comprehensive guidance and support in completing academic tasks.",
      "As an academic support provider, Talk With Guru takes an active initiative to provide Quiz assistance, bringing students closer to achieving an A+ grade. Our dedicated team of writing specialists ensures that you are never left alone in challenging situations. Whether you declare 'I need help with my Quizzes' or seek assistance with tight deadlines, our 24/7 service commitment remains unwavering.",
      "This dedication has earned us a reputation as the premier academic assistance provider globally, solidifying Talk With Guru' position in the EdTech industry. Elevate your Quiz experience with excellence, only at Talk With Guru Quizzes.",
    ],
    serviceWhySectionPara: [
      "Curious about why you need this particular Quizzes section? Academic pursuits often present unexpected challenges, and ensuring that your academic journey aligns with aspirations can be a significant hurdle. Turning to a professional academic assistance provider, like Talk With Guru, proves beneficial in such circumstances.",
      "Seeking help from Talk With Guru goes beyond improving academic performance – it fosters student growth without the burden of stress. Our comprehensive guidance and support in completing academic tasks set us apart as your reliable assistance expert.",
      "Talk With Guru takes an active initiative to provide Quiz assistance, bringing you closer to achieving academic excellence. Our dedicated team of specialists ensures you're never left alone in challenging situations. Whether you declare 'I need help with my Quizzes' or navigate tight deadlines, our 24/7 service commitment remains unwavering.",
      "This dedication has earned us a global reputation as the premier academic assistance provider, solidifying our position in the EdTech industry. Discover the compelling reasons behind the indispensability of the Quizzes section for your academic success with Talk With Guru Quizzes.",
    ],
  },
  // dissertations
  {
    seoTitle:
      "Get Dissertation Writing Services for Academic Success | Talk With Guru",
    seoDescription:
      "Unlock academic achievements with our professional dissertation writing services. Experienced writers, timely delivery, and top-notch quality for your success.",
    name: "Dissertation help",
    href: "dissertations-help",
    label: "dissertations help",
    bannerTitle: "CHEAP DISSERTATION ONLINE SERVICES",
    bannerDesc:
      "The Talk With Guru Offers Low Price Online Dissertation Writing Services with additional Freebies",
    aboutTitle: "Dissertation Ghost Writers",
    aboutImage: dissertations,
    serviceAboutSectionPara: [
      "Our Dissertation Ghost Writers are highly skilled into writing dissertation in any person's voice. Embark on an academic journey of excellence with Talk With Guru Dissertations – your go-to destination for unparalleled quality in dissertation assistance. Do academic pursuits always unfold as anticipated? More often than not, the answer is a resounding No. While planning your career path may seem secure, ensuring that your academic journey aligns with those aspirations poses a significant challenge.",
      "In such circumstances, turning to Talk With Guru, a professional academic assistance provider, proves beneficial. Seeking help from us not only contributes to improved academic performance but also fosters student growth without the burden of stress. At Talk With Guru, we stand as a reliable assistance expert, offering comprehensive guidance and support in completing academic tasks.",
      "As an academic support provider, Talk With Guru takes an active initiative to provide dissertation assistance, bringing students closer to achieving an A+ grade. Our dedicated team of writing specialists ensures that you are never left alone in challenging situations. Whether you declare 'I need help with my dissertation' or seek assistance with tight deadlines, our 24/7 service commitment remains unwavering.",
      "This dedication has earned us a reputation as the premier academic assistance provider globally, solidifying Talk With Guru' position in the EdTech industry. Join us at Talk With Guru Dissertations and revolutionize your academic path.",
    ],
    whyTitle: "Is Doing a Dissertation Worth it ?",
    serviceWhySectionPara: [
      "Yes, Here is how, Academic pursuits often present unexpected challenges, and ensuring that your academic journey aligns with aspirations can be a significant hurdle. Turning to a professional academic assistance provider, like Talk With Guru, proves beneficial in such circumstances.",
      "Seeking help from Talk With Guru goes beyond improving academic performance – it fosters student growth without the burden of stress. We stand as your reliable assistance expert, offering comprehensive guidance and support in completing academic tasks.",
      "Talk With Guru takes an active initiative to provide dissertation assistance, bringing you closer to achieving academic excellence. Our dedicated team of specialists ensures you're never left alone in challenging situations. Whether you declare 'I need help with my dissertation' or navigate tight deadlines, our 24/7 service commitment remains unwavering.",
      "This dedication has earned us a global reputation as the premier academic assistance provider, solidifying our position in the EdTech industry. Discover the compelling reasons behind the indispensability of the Dissertations section for your academic success with Talk With Guru Dissertations.",
    ],
  },
  // research-paper-help/////////
  {
    seoTitle: "Expert Research Paper Help Services | Talk With Guru",
    seoDescription: "Get top-notch research paper help services at Talk With Guru. Our experienced tutors ensure accurate and comprehensive research papers, meeting academic standards.",
    name: "Research Paper Help",
    href: "research-paper-help",
    label: "research paper help",
    bannerDesc: "Revolutionize your career with online homework \n help available today!",
    aboutTitle: "Talk With Guru Research Paper Help Services: Elevate Your Academic Research with Top-Quality Papers",
    aboutImage: labReport,
    serviceAboutSectionPara: [
      "Welcome to Talk With Guru Research Paper Help Services – your go-to destination for top-notch research paper services. Academic pursuits don't always unfold as anticipated, and planning your career path might seem secure, but aligning your academic journey with aspirations poses a significant challenge.",
      "In such circumstances, turning to a professional academic assistance provider proves beneficial. Seeking help not only contributes to improved academic performance but also fosters student growth without the burden of stress. Talk With Guru, a reliable assistance expert, offers comprehensive guidance and support in completing academic tasks.",
      "As an academic support provider, Talk With Guru takes an active initiative to provide exceptional research paper services. Our dedicated team of specialists ensures that you are never left alone in challenging situations. Whether you declare 'I need help with my research paper' or seek assistance with tight deadlines, our 24/7 service commitment remains unwavering.",
      "This dedication has earned us a reputation as the premier academic assistance provider globally, solidifying Talk With Guru' position in the EdTech industry. Experience excellence in research papers with Talk With Guru."
    ],
    serviceWhySectionPara: [
      "Wondering why you need this particular research paper section? Academic pursuits often present unexpected challenges, and ensuring that your academic journey aligns with aspirations can be a significant hurdle. Turning to a professional academic assistance provider, like Talk With Guru, proves beneficial in such circumstances.",
      "Seeking help from Talk With Guru goes beyond improving academic performance – it fosters student growth without the burden of stress. Our comprehensive guidance and support in completing academic tasks set us apart as your reliable assistance expert.",
      "Talk With Guru takes an active initiative to provide research paper services, bringing you closer to achieving academic excellence. Our dedicated team of specialists ensures you're never left alone in challenging situations. Whether you declare 'I need help with my research paper' or navigate tight deadlines, our 24/7 service commitment remains unwavering.",
      "This dedication has earned us a global reputation as the premier academic assistance provider, solidifying our position in the EdTech industry. Explore why this research paper section is indispensable on your journey to academic success with Talk With Guru Research Paper Help."
    ]
  },
  // thesis-help /////////////
  {
    seoTitle: "Expert Thesis Help Services | Talk With Guru",
    seoDescription: "Get top-notch thesis help services at Talk With Guru. Our experienced tutors ensure accurate and comprehensive theses, meeting academic standards.",
    name: "Thesis Help",
    href: "thesis-help",
    label: "thesis help",
    bannerDesc: "Revolutionize your career with online homework \n help available today!",
    aboutTitle: "Talk With Guru Thesis Help Services: Elevate Your Academic Thesis with Top-Quality Assistance",
    aboutImage: labReport,
    serviceAboutSectionPara: [
      "Welcome to Talk With Guru Thesis Help Services – your go-to destination for top-notch thesis services. Academic pursuits don't always unfold as anticipated, and planning your career path might seem secure, but aligning your academic journey with aspirations poses a significant challenge.",
      "In such circumstances, turning to a professional academic assistance provider proves beneficial. Seeking help not only contributes to improved academic performance but also fosters student growth without the burden of stress. Talk With Guru, a reliable assistance expert, offers comprehensive guidance and support in completing academic tasks.",
      "As an academic support provider, Talk With Guru takes an active initiative to provide exceptional thesis services. Our dedicated team of specialists ensures that you are never left alone in challenging situations. Whether you declare 'I need help with my thesis' or seek assistance with tight deadlines, our 24/7 service commitment remains unwavering.",
      "This dedication has earned us a reputation as the premier academic assistance provider globally, solidifying Talk With Guru' position in the EdTech industry. Experience excellence in theses with Talk With Guru."
    ],
    serviceWhySectionPara: [
      "Wondering why you need this particular thesis section? Academic pursuits often present unexpected challenges, and ensuring that your academic journey aligns with aspirations can be a significant hurdle. Turning to a professional academic assistance provider, like Talk With Guru, proves beneficial in such circumstances.",
      "Seeking help from Talk With Guru goes beyond improving academic performance – it fosters student growth without the burden of stress. Our comprehensive guidance and support in completing academic tasks set us apart as your reliable assistance expert.",
      "Talk With Guru takes an active initiative to provide thesis services, bringing you closer to achieving academic excellence. Our dedicated team of specialists ensures you're never left alone in challenging situations. Whether you declare 'I need help with my thesis' or navigate tight deadlines, our 24/7 service commitment remains unwavering.",
      "This dedication has earned us a global reputation as the premier academic assistance provider, solidifying our position in the EdTech industry. Explore why this thesis section is indispensable on your journey to academic success with Talk With Guru Thesis Help."
    ]
  },
  // lab-report
  {
    seoTitle: "Expert Lab Report Writing Services | Talk With Guru",
    seoDescription:
      "Get top-notch lab report writing services at Talk With Guru. Our experienced tutors ensure accurate and comprehensive lab reports, meeting academic standards. ",
    name: "Lab Report Help",
    href: "lab-report-help",
    label: "lab report help",
    bannerDesc:
      "Revolutionize your career with online homework \n help available today!",
    aboutTitle:
      "Talk With Guru Lab Report Services: Elevate Your Scientific Narrative with Top-Quality Lab Reports",
    aboutImage: labReport,
    serviceAboutSectionPara: [
      "Welcome to Talk With Guru Lab Reports – your go-to destination for top-notch Lab Report services. Academic pursuits don't always unfold as anticipated, and planning your career path might seem secure, but aligning your academic journey with aspirations poses a significant challenge.",
      "In such circumstances, turning to a professional academic assistance provider proves beneficial. Seeking help not only contributes to improved academic performance but also fosters student growth without the burden of stress. Talk With Guru, a reliable assistance expert, offers comprehensive guidance and support in completing academic tasks.",
      "As an academic support provider, Talk With Guru takes an active initiative to provide exceptional Lab Report services. Our dedicated team of specialists ensures that you are never left alone in challenging situations. Whether you declare 'I need help with my Lab Report' or seek assistance with tight deadlines, our 24/7 service commitment remains unwavering.",
      "This dedication has earned us a reputation as the premier academic assistance provider globally, solidifying Talk With Guru' position in the EdTech industry. Experience excellence in Lab Reports with Talk With Guru.",
    ],
    serviceWhySectionPara: [
      "Wondering why you need this particular Lab Report section? Academic pursuits often present unexpected challenges, and ensuring that your academic journey aligns with aspirations can be a significant hurdle. Turning to a professional academic assistance provider, like Talk With Guru, proves beneficial in such circumstances.",
      "Seeking help from Talk With Guru goes beyond improving academic performance – it fosters student growth without the burden of stress. Our comprehensive guidance and support in completing academic tasks set us apart as your reliable assistance expert.",
      "Talk With Guru takes an active initiative to provide Lab Report services, bringing you closer to achieving academic excellence. Our dedicated team of specialists ensures you're never left alone in challenging situations. Whether you declare 'I need help with my Lab Report' or navigate tight deadlines, our 24/7 service commitment remains unwavering.",
      "This dedication has earned us a global reputation as the premier academic assistance provider, solidifying our position in the EdTech industry. Explore why this Lab Report section is indispensable on your journey to academic success with Talk With Guru Lab Reports.",
    ],
  },
  // term-paper-help/////////
  {
    seoTitle: "Expert Term Paper Help Services | Talk With Guru",
    seoDescription: "Get top-notch term paper help services at Talk With Guru. Our experienced tutors ensure accurate and comprehensive term papers, meeting academic standards.",
    name: "Term Paper Help",
    href: "term-paper-help",
    label: "term paper help",
    bannerDesc: "Revolutionize your career with online homework \n help available today!",
    aboutTitle: "Talk With Guru Term Paper Help Services: Elevate Your Academic Term Paper with Top-Quality Assistance",
    aboutImage: labReport,
    serviceAboutSectionPara: [
      "Welcome to Talk With Guru Term Paper Help Services – your go-to destination for top-notch term paper services. Academic pursuits don't always unfold as anticipated, and planning your career path might seem secure, but aligning your academic journey with aspirations poses a significant challenge.",
      "In such circumstances, turning to a professional academic assistance provider proves beneficial. Seeking help not only contributes to improved academic performance but also fosters student growth without the burden of stress. Talk With Guru, a reliable assistance expert, offers comprehensive guidance and support in completing academic tasks.",
      "As an academic support provider, Talk With Guru takes an active initiative to provide exceptional term paper services. Our dedicated team of specialists ensures that you are never left alone in challenging situations. Whether you declare 'I need help with my term paper' or seek assistance with tight deadlines, our 24/7 service commitment remains unwavering.",
      "This dedication has earned us a reputation as the premier academic assistance provider globally, solidifying Talk With Guru' position in the EdTech industry. Experience excellence in term papers with Talk With Guru."
    ],
    serviceWhySectionPara: [
      "Wondering why you need this particular term paper section? Academic pursuits often present unexpected challenges, and ensuring that your academic journey aligns with aspirations can be a significant hurdle. Turning to a professional academic assistance provider, like Talk With Guru, proves beneficial in such circumstances.",
      "Seeking help from Talk With Guru goes beyond improving academic performance – it fosters student growth without the burden of stress. Our comprehensive guidance and support in completing academic tasks set us apart as your reliable assistance expert.",
      "Talk With Guru takes an active initiative to provide term paper services, bringing you closer to achieving academic excellence. Our dedicated team of specialists ensures you're never left alone in challenging situations. Whether you declare 'I need help with my term paper' or navigate tight deadlines, our 24/7 service commitment remains unwavering.",
      "This dedication has earned us a global reputation as the premier academic assistance provider, solidifying our position in the EdTech industry. Explore why this term paper section is indispensable on your journey to academic success with Talk With Guru Term Paper Help."
    ]
  },
  // write-my-case-study
  {
    seoTitle: "Get Your Case Study Written by Professionals|Talk With Guru",
    seoDescription:
      "Discover excellence in case study writing with Talk With Guru. We offer personalized solutions, ensuring your case study stands out. Trust us for top-notch work.",
    name: "Case Study Help",
    href: "case-study-help",
    label: "case study help",
    bannerDesc:
      "Revolutionize your career with online homework \n help available today!",
    aboutTitle:
      "Case Study Excellence Unveiled: Talk With Guru' Tailored Solutions in Crafting Your Narrative with Precision",
    aboutImage: caseStudy,
    serviceAboutSectionPara: [
      "At Talk With Guru, we recognize that academic pursuits don't always unfold as anticipated. Planning your career path might seem secure, but aligning your academic journey with aspirations poses a significant challenge.",
      "In such circumstances, seeking professional academic assistance becomes a game-changer. It not only contributes to improved academic performance but also fosters student growth without the burden of stress. Enter Talk With Guru – your reliable partner offering comprehensive guidance and support to navigate the complexities of academic tasks.",
      "As academic support providers, we take an active initiative to provide live sessions, bringing you closer to achieving that coveted A+ grade. Our dedicated team of writing specialists ensures you're never alone in challenging situations. Whether you declare 'I need help with writing an assignment' or require assistance with tight deadlines, our unwavering 24/7 service commitment remains your academic safety net.",
      "This unwavering dedication has earned us a reputation as the premier academic assistance provider globally, solidifying Talk With Guru' position in the EdTech industry.",
    ],
    serviceWhySectionPara: [
      "Why should you choose Talk With Guru for your 'Write My Case Study' needs? Let's delve into the reasons that make us stand out:",
      "1. **Expert Assistance:** Our service goes beyond basic help. We provide expert assistance, ensuring your case study meets the highest academic standards and showcases your understanding of the subject.",
      "2. **Comprehensive Guidance:** Talk With Guru takes an active initiative to provide comprehensive guidance and support in completing academic tasks. We don't just solve problems; we empower you to excel.",
      "3. **Live Sessions for Excellence:** Experience a dynamic learning environment. Our live sessions are designed to bring you closer to achieving an A+ grade, a testament to our commitment to your success.",
      "4. **Round-the-Clock Support:** Academic challenges can arise at any time. Whether it's 'I need help with writing an assignment' or dealing with tight deadlines, our 24/7 dedicated support ensures you're covered.",
      "Choose Talk With Guru for your 'Write My Case Study' requirements, and witness a transformation in your academic journey. Your success is not just a goal; it's our commitment.",
    ],
  },
];

export const faq = [
  // General FAQ
  {
    id: 1,
    title: "Why Choose Alpha Tutor's tutoring services?",
    content: [
      "- Tutoring on more than 100+ subjects",
      "- PhD Experts with 10+ years of experience offering tutoring Help",
      "- Lowest possible prices for tutoring",
      "- Amazing 24x7 live support — highly qualified, experienced and friendly customer service executives",
      "- Experience our services to believe in us",
    ],
  },
  {
    id: 2,
    title: "For what levels, do you provide tutoring?",
    content: [
      "We provide tutoring and guidance for all levels from primary, high school, grad and post grad levels.",
    ],
  },
  {
    id: 3,
    title: "What are the areas and domains in which you provide tutoring?",
    content: [
      "We provide expert guidance on Management, Law , Literature, English, Science, Maths etc.",
    ],
  },
  {
    id: 4,
    title: "How is your tutoring service different from others?",
    content: [
      "Our PhD tutors have been drawn from reputed institutes having 10+ years of teaching experience in various institutes. They have been trained on best learning methodologies. We have PhDs drawn from various domains and specialisations. We have an inhouse benchmark where only those tutors are selected whose tutoring enabled student to get A grades. Not only that, top 5% of the academic writers guide you during the writing process.",
    ],
  },
  {
    id: 5,
    title: "How do I trust you with any payment?",
    content: [
      "You are given an order confirmation number as soon as you make the payment. We ensure that the most credible and safe gateway is used for payment with firewall and multiple security options. 24* 7 live support is provided for all payment details.",
    ],
  },
  {
    id: 6,
    title: "How do I know that I would get quality tutors?",
    content: ["You can have live interaction with our tutors along with"],
  },
  {
    id: 7,
    title: "Can I talk with your tutor?",
    content: [
      "Yes, you can have live interaction with out tutors anytime you face a problem.",
    ],
  },
  {
    id: 8,
    title: "Are my personal details provided to my tutor?",
    content: [
      "No, your personal details are neither divulged to any party nor provided to your tutors",
    ],
  },
  // services FAQ
  {
    label: "services",
    id: 9,
    title: "Why Choose Alpha Tutor's writing services?",
    content: [
      // services related content here
    ],
  },
  {
    label: "services",
    id: 10,
    title: "For what levels, do you provide writing assistance?",
    content: [
      // services related content here
    ],
  },
  {
    label: "services",
    id: 11,
    title: "What are the areas and domains in which you provide academic help?",
    content: [
      // services related content here
    ],
  },
  {
    label: "services",
    id: 12,
    title: "How is your writing service different from others?",
    content: [
      // services related content here
    ],
  },
  {
    label: "services",
    id: 13,
    title: "How do I trust you with any payment?",
    content: [
      // services related content here
    ],
  },
  {
    label: "services",
    id: 14,
    title: "How do I know that I would get quality work?",
    content: [
      // services related content here
    ],
  },
  {
    label: "services",
    id: 15,
    title: "Can I talk with your tutor?",
    content: [
      // services related content here
    ],
  },
  {
    label: "services",
    id: 16,
    title: "Are my personal details provided to my helper?",
    content: [
      // services related content here
    ],
  },
  // Assignment Services FAQ
  {
    label: "assignment-service",
    id: 17,
    title: "How does your assignment writing service work?",
    content: [
      // Add assignment service related content here
      "The Talk With Guru assignment writing process involves clients providing task details, receiving a cost estimate, making a 50% advance payment for assignment initiation, getting timely notifications on completion, settling the remaining payment for the finished assignment, and accommodating free revisions with potential additional costs for new changes. This organized and transparent approach emphasizes clear communication and sets expectations for Talk With Guru' clients.",
    ],
  },
  {
    label: "assignment-help",
    id: 18,
    title:
      "What are the key benefits of using a professional assignment writing service?",
    content: [
      // Add assignment service related content here
      "Engaging with Talk With Guru assures expertly crafted, plagiarism-free assignments tailored to client needs, reducing stress and providing learning opportunities, all while maintaining strict confidentiality and offering convenient, 24/7 support.",
    ],
  },
  {
    label: "assignment-help",
    id: 19,
    title:
      "How much does it cost to get an assignment written by your service?",
    content: [
      // Add assignment service related content here
      "For pricing details on our assignment writing service, reach out via WhatsApp or email, where we assess costs based on complexity, tutor charges, and deadlines, ensuring a customized quote aligned with your specific requirements.",
    ],
  },
  {
    label: "assignment-help",
    id: 20,
    title: "How quickly can I get my assignment done if it's urgent?",
    content: [
      // Add assignment service related content here
      "The speed of completing your assignment depends on factors like task complexity, expert availability, and your provided deadline. It's crucial for the deadline to be realistic; extremely tight turnarounds may be impractical, but if the project requires only 3-4 hours of work, it can be accommodated accordingly.",
    ],
  },
  {
    label: "assignment-help",
    id: 21,
    title: "Are your services available for international students as well?",
    content: [
      // Add assignment service related content here
      "Absolutely, our services are available for students, professionals, and clients worldwide. We cater to individuals from all corners of the globe.",
    ],
  },
  {
    label: "assignment-help",
    id: 22,
    title: "What subjects do you cover in your assignment writing services?",
    content: [
      // Add assignment service related content here
      "Certainly, we offer assistance across all subjects, covering diverse fields and courses, including support for company projects.",
    ],
  },
  {
    label: "assignment-help",
    id: 23,
    title: "Why Choose us for assignment writing services?",
    content: [
      // Add assignment service related content here
      "Opt for Talk With Guru for assignment writing services, featuring 800+ tutors, 24/7 support, timely delivery, affordable pricing, plagiarism-free content, and secured refunds via Paypal and Razorpay. Enjoy benefits like unlimited revisions, AI-free assignments, confidentiality, assistance from multiple experts, rewards, lifetime access, free SMS updates, and on-demand phone calls with our relationship manager for instant query resolution.",
    ],
  },
  // essay writing Services FAQ
  {
    label: "essay-writing-services",
    id: 24,
    title: "",
    content: [
      // Add essay writing service related content here
    ],
  },
  {
    label: "essay-writing-services",
    id: 25,
    title: "Are your essays plagiarism-free?",
    content: [
      // Add essay writing service related content here
    ],
  },
  {
    label: "essay-writing-services",
    id: 26,
    title: "What are the benefits of using essay writing services?",
    content: [
      // Add essay writing service related content here
    ],
  },
  {
    label: "essay-writing-services",
    id: 27,
    title: "Is it OK to use an essay writing service?",
    content: [
      // Add essay writing service related content here
    ],
  },
  {
    label: "essay-writing-services",
    id: 28,
    title: "How much do essay writing services cost?",
    content: [
      // Add essay writing service related content here
    ],
  },
  {
    label: "essay-writing-services",
    id: 29,
    title: "Are revisions included in the service, and how many can I request?",
    content: [
      // Add essay writing service related content here
    ],
  },
  {
    label: "essay-writing-services",
    id: 30,
    title: "Do you guarantee the quality of your work?",
    content: [
      // Add essay writing service related content here
    ],
  },
  {
    label: "essay-writing-services",
    id: 31,
    title: "Why choose us for essay writing services ?",
    content: [
      // Add essay writing service related content here
    ],
  },
  // project-report-writing FAQ
  {
    label: "project-report-writing",
    id: 32,
    title: "What services do you offer for project report writing?",
    content: [
      // Add project-report-writing service related content here
    ],
  },
  {
    label: "project-report-writing",
    id: 33,
    title: "How do you ensure the quality of the project reports?",
    content: [
      // Add project-report-writing service related content here
    ],
  },
  {
    label: "project-report-writing",
    id: 34,
    title: "Is my information kept confidential?",
    content: [
      // Add project-report-writing service related content here
    ],
  },
  {
    label: "project-report-writing",
    id: 35,
    title: "How much does project report writing service cost?",
    content: [
      // Add project-report-writing service related content here
    ],
  },
  {
    label: "project-report-writing",
    id: 36,
    title:
      "Can I request specific sections to be included in the project report?",
    content: [
      // Add project-report-writing service related content here
    ],
  },
  {
    label: "project-report-writing",
    id: 37,
    title: "Do you provide custom formatting for project reports?",
    content: [
      // Add project-report-writing service related content here
    ],
  },
  {
    label: "project-report-writing",
    id: 38,
    title: "How long does it take to write a project report?",
    content: [
      // Add project-report-writing service related content here
    ],
  },
  {
    label: "project-report-writing",
    id: 39,
    title: "What information do I need to provide you?",
    content: [
      // Add project-report-writing service related content here
    ],
  },
  {
    label: "project-report-writing",
    id: 40,
    title: "Why choose us for project report writing services?",
    content: [
      // Add project-report-writing service related content here
    ],
  },
  // speech-writing FAQ
  {
    label: "speech-writing",
    id: 41,
    title: "Can you help with speeches for different occasions?",
    content: [
      // Add speech-writing service related content here
    ],
  },
  {
    label: "speech-writing",
    id: 42,
    title: "How much does your speech writing service cost?",
    content: [
      // Add speech-writing service related content here
    ],
  },
  {
    label: "speech-writing",
    id: 43,
    title: "Can you provide last-minute speech writing services?",
    content: [
      // Add speech-writing service related content here
    ],
  },
  {
    label: "speech-writing",
    id: 44,
    title: "Are there sample speeches I can review before hiring your service?",
    content: [
      // Add speech-writing service related content here
    ],
  },
  {
    label: "speech-writing",
    id: 45,
    title: "How can I provide input on the speech writing process?",
    content: [
      // Add speech-writing service related content here
    ],
  },
  {
    label: "speech-writing",
    id: 46,
    title: "What information do I need to provide you to get started?",
    content: [
      // Add speech-writing service related content here
    ],
  },
  {
    label: "speech-writing",
    id: 47,
    title: "Why Choose us for speech writing?",
    content: [
      // Add speech-writing service related content here
    ],
  },
  // presentation-writing FAQ
  {
    label: "presentation-writing",
    id: 48,
    title: "What services do you offer for presentation writing?",
    content: [
      // Add presentation writing service related content here
      "We offer custom-made PowerPoint presentations crafted by experts to meet your specific needs and requirements.",
    ],
  },
  {
    label: "presentation-writing",
    id: 49,
    title: "Can you handle urgent requests for presentation writing?",
    content: [
      // Add presentation writing service related content here
      "Yes, we can handle urgent requests for presentation writing.",
    ],
  },
  {
    label: "presentation-writing",
    id: 50,
    title: "Are my details and information kept confidential?",
    content: [
      // Add presentation writing service related content here
      "Yes, your details and information are kept confidential.",
    ],
  },
  {
    label: "presentation-writing",
    id: 51,
    title: "How much does presentation writing cost?",
    content: [
      // Add presentation writing service related content here
      "The cost of presentation writing varies depending on factors such as the complexity of the project, the length of the presentation, and any additional requirements. We offer customized pricing based on your specific needs.",
    ],
  },
  {
    label: "presentation-writing",
    id: 52,
    title: "How long does it take to complete a presentation?",
    content: [
      // Add presentation writing service related content here
      "The time it takes to complete a presentation depends on factors such as its length, complexity, and any specific requirements. We strive to deliver presentations in a timely manner while ensuring high quality.",
    ],
  },
  {
    label: "presentation-writing",
    id: 53,
    title: "What revisions are included in presentation writing services?",
    content: [
      // Add presentation writing service related content here
      "Revisions included in our presentation writing services are based on the initial requirements provided. Minor revisions are typically done free of charge, ensuring the content aligns with your specifications. However, significant changes may incur additional charges, and we'll discuss this with you before proceeding.",
    ],
  },
  {
    label: "presentation-writing",
    id: 54,
    title: "Why choose us for presentation writing services?",
    content: [
      // Add presentation writing service related content here
      "Choose us for presentation writing services because we craft PowerPoint presentations tailored to your instructions, ensuring timely delivery without relying on AI tools.",
    ],
  },
  // homework FAQ
  {
    label: "homework",
    id: 55,
    title: "How does your homework help service work?",
    content: [
      // Add homework writing service related content here
    ],
  },
  {
    label: "homework",
    id: 56,
    title: "What services do you offer for homework help?",
    content: [
      // Add homework writing service related content here
    ],
  },
  {
    label: "homework",
    id: 57,
    title: "Is the homework help service confidential?",
    content: [
      // Add homework writing service related content here
    ],
  },
  {
    label: "homework",
    id: 58,
    title: "How quickly can I get my homework done using your service?",
    content: [
      // Add homework writing service related content here
    ],
  },
  {
    label: "homework",
    id: 59,
    title:
      "Do you provide support for students at different academic levels (high school, college, etc.)?",
    content: [
      // Add homework writing service related content here
    ],
  },
  {
    label: "homework",
    id: 60,
    title: "How much does your homework help service cost?",
    content: [
      // Add homework writing service related content here
    ],
  },
  {
    label: "homework",
    id: 61,
    title: "Why choose us for homework help?",
    content: [
      // Add homework writing service related content here
    ],
  },
  {
    label: "homework",
    id: 144,
    title: "Coding homework help free?",
    content: [
      // Add homework writing service related content here
      "You can go to this Website https://talkwithguru.com/services/programming",
    ],
  },
  // dissertations FAQ
  {
    label: "dissertations",
    id: 62,
    title: "What services do you offer for dissertation writing?",
    content: [
      // Add dissertations service related content here
      "We assist students in writing plagiarism-free dissertations to help them succeed in their academic endeavors.",
    ],
  },
  {
    label: "dissertations",
    id: 63,
    title: "Can I communicate with the writer working on my dissertation?",
    content: [
      // Add dissertations service related content here
      "Certainly! You can communicate with the writer working on your dissertation through us. We act as an intermediary, facilitating direct communication between you and the expert writer via virtual meetings to ensure efficient collaboration and the best outcome for your project.",
    ],
  },
  {
    label: "dissertations",
    id: 64,
    title:
      "What guarantees do you offer regarding the quality of the dissertation?",
    content: [
      // Add dissertations service related content here
      "Absolutely! We provide a free Turnitin report to ensure the uniqueness and high quality of the dissertation, aiming to secure the best grades for you.",
    ],
  },
  {
    label: "dissertations",
    id: 65,
    title: "How long does it take to complete a dissertation?",
    content: [
      // Add dissertations service related content here
      "We strive to complete dissertations within the given deadline, ensuring timely delivery whenever humanly pos",
    ],
  },
  {
    label: "dissertations",
    id: 66,
    title: "Can I request revisions after receiving the final dissertation?",
    content: [
      // Add dissertations service related content here
      "Certainly, revisions that involve major changes or deviate from the initial instructions may be subject to additional charges. We strive to ensure that the final dissertation meets your expectations based on the original requirements provided.",
    ],
  },
  {
    label: "dissertations",
    id: 67,
    title:
      "Are your dissertation writing services available for all academic levels and subjects?",
    content: [
      // Add dissertations service related content here
      "Yes, our dissertation writing services are available for all academic levels and subjects. ",
    ],
  },
  {
    label: "dissertations",
    id: 68,
    title: "How much does your dissertation writing service cost?",
    content: [
      // Add dissertations service related content here
      "Our dissertation writing service is priced on a custom basis, as the cost may vary depending on factors such as the academic level, subject area, complexity of the topic, deadline, and specific requirements of the project. However, as a rough estimate, our pricing typically starts at around $11 USD per page. Keep in mind that this is just a general guideline, and the final cost may be adjusted based on your unique needs. We aim to provide competitive pricing while ensuring high-quality work that meets your expectations. Feel free to contact us with your project details for a personalized quote.",
    ],
  },
  {
    label: "dissertations",
    id: 140,
    title: "Writing dissertation in 2 weeks?",
    content: [
      // Add dissertations service related content here
      "Our Ghostwriters can complete your dissertation within 5 business days.",
    ],
  },
  {
    label: "dissertations",
    id: 141,
    title: "Writing conclusion for dissertation?",
    content: [
      // Add dissertations service related content here
      "Its hard to write a conclusion for dissertation. Tell our write your journey and he'll get you the perfect conclusion to end your dissertation",
    ],
  },

  // exams service FAQ
  {
    label: "exams-help",
    id: 104,
    title: "Can I find help for my online exam?",
    content: [
      // Add exams service related content here
      "Yes, we offer assistance with online exams.",
    ],
  },
  {
    label: "exams-help",
    id: 105,
    title: "How can a website help me on my online exams? ",
    content: [
      // Add exams service related content here
      "You can find help for your online exam by booking tutoring sessions in advance through our website. Simply connect with us on WhatsApp to schedule a tutoring session for a specific time slot that suits your schedule.",
    ],
  },
  {
    label: "exams-help",
    id: 106,
    title: "Is it possible to hire someone to take my online exam?",
    content: [
      // Add exams service related content here
      "Yes, it is possible to hire someone to take your online exam. If this is something you need assistance with, please contact us on WhatsApp, and we can discuss the details further. We strive to accommodate our clients' needs to the best of our ability, provided it falls within our scope of services.",
    ],
  },
  {
    label: "exams-help",
    id: 107,
    title: "How does your online exam help service work?",
    content: [
      // Add exams service related content here
      "Our online exam help service works by allowing you to book a slot with us for the needed point of time through WhatsApp. Once you've contacted us and scheduled a slot, we will provide you with the necessary assistance during your online exam.",
    ],
  },
  {
    label: "exams-help",
    id: 108,
    title: "What subjects and exams do you cover?",
    content: [
      // Add exams service related content here
      "We cover a wide range of subjects and exams. Simply let us know your specific requirements on WhatsApp, and we'll do our best to accommodate your needs.",
    ],
  },
  {
    label: "exams-help",
    id: 109,
    title: "Is the online exam help service secure and confidential?",
    content: [
      // Add exams service related content here
      "Yes, our online exam help service is secure and confidential.",
    ],
  },
  {
    label: "exams-help",
    id: 110,
    title: "Is the online exam help service available 24/7?",
    content: [
      // Add exams service related content here
      "Yes, our online exam help service is available 24/7.",
    ],
  },
  {
    label: "exams-help",
    id: 111,
    title: "Why choose us for online exam help?",
    content: [
      // Add exams service related content here
      "You should choose us for online exam help because we have been providing this service for the past five years with a team of experts. Our experts are proficient in using tools like AnyDesk to meet the needs of our clients efficiently.",
    ],
  },
  // quizzes FAQ
  {
    label: "quizzes-help",
    id: 112,
    title: "How does the Online Quiz Help works?",
    content: [
      // Add quizzes service related content here
    ],
  },
  {
    label: "quizzes-help",
    id: 113,
    title: "Is it legal to use Online Quiz Help services?",
    content: [
      // Add quizzes service related content here
    ],
  },
  {
    label: "quizzes-help",
    id: 114,
    title: "hat subjects and topics do you cover for online quizzes?",
    content: [
      // Add quizzes service related content here
    ],
  },
  {
    label: "quizzes-help",
    id: 115,
    title: "Is my information kept confidential?",
    content: [
      // Add quizzes service related content here
    ],
  },
  {
    label: "quizzes-help",
    id: 116,
    title:
      "How can I trust the expertise of the professionals providing assistance?",
    content: [
      // Add quizzes service related content here
    ],
  },
  {
    label: "quizzes-help",
    id: 117,
    title: "What types of online quizzes do you support?",
    content: [
      // Add quizzes service related content here
    ],
  },
  {
    label: "quizzes-help",
    id: 118,
    title: "Do you offer help for time-sensitive quizzes or exams?",
    content: [
      // Add quizzes service related content here
    ],
  },
  {
    label: "quizzes-help",
    id: 119,
    title: "Why choose us for online quizzes help?",
    content: [
      // Add quizzes service related content here
    ],
  },
  // lab-report FAQ
  {
    label: "lab-report-help",
    id: 132,
    title: "What type of lab reports do you provide?",
    content: [
      // Add lab-report service related content here
      "Certainly! In addition to physics, chemistry, and all branches of engineering, we also provide laboratory reports for mathematical experiments and analyses. Our team is equipped to handle a wide range of subjects and disciplines to meet your academic or research needs.",
    ],
  },
  {
    label: "lab-report-help",
    id: 133,
    title: "Who are the writers responsible for crafting the lab reports?",
    content: [
      // Add lab-report service related content here
      "Our lab reports are crafted by top-class experts specialized in physics, chemistry, engineering, mathematics, and other relevant fields, ensuring high-quality, tailored content that meets university standards.",
    ],
  },
  {
    label: "lab-report-help",
    id: 134,
    title: "How can I track the progress of my lab report writing?",
    content: [
      // Add lab-report service related content here
      "You can track the progress of your lab report writing by contacting us anytime, as we are available 24/7 to provide updates and address any queries you may have. Simply reach out to us via WhatsApp, and we'll be happy to assist you.",
    ],
  },
  {
    label: "lab-report-help",
    id: 135,
    title: "What is the cost structure of lab report writing?",
    content: [
      // Add lab-report service related content here
      "We offer customized pricing for lab report writing based on project requirements, ensuring fair and accurate cost estimation.",
    ],
  },
  {
    label: "lab-report-help",
    id: 136,
    title: "Do you guarantee plagiarism-free work?",
    content: [
      // Add lab-report service related content here
      "Yes, we guarantee plagiarism-free lab reports and provide a complimentary Turnitin report free of cost to ensure originality.",
    ],
  },
  {
    label: "lab-report-help",
    id: 137,
    title:
      "Can you help me with the introduction or conclusion of my lab report?",
    content: [
      // Add lab-report service related content here
      "Yes, we can assist you with crafting the introduction or conclusion of your lab report.",
    ],
  },
  {
    label: "lab-report-help",
    id: 138,
    title: "Do you offer rush services for urgent deadlines?",
    content: [
      // Add lab-report service related content here
      "Yes, we do offer rush services for urgent deadlines. However, we need to review all your requirements beforehand to ensure feasibility within the given timeframe.",
    ],
  },
  {
    label: "lab-report-help",
    id: 139,
    title: "Why choose us for lab report writing?",
    content: [
      // Add lab-report service related content here
      "Choose us for lab report writing because we have experts from various fields who deliver timely work with plagiarism-free content, ensuring you receive the best grades.",
    ],
  },
];