import React from 'react';
import SEO from '../common/SEO';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import FooterOne from '../common/footer/FooterOne';
import BrandOne from '../component/brand/BrandOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import ServicePropOne from '../component/service/ServicePropOne';
import SectionTitle from '../elements/section-title/SectionTitle';
import BannerFive from '../component/banner/BannerFive';
import HeaderFour from '../common/header/HeaderFour';
import AboutOne from '../component/about/AboutOne';
import CounterUpOne from '../component/counterup/CounterUpOne';
import PricingOne from '../component/pricing/PricingOne';
import PricingCalculator from "../component/pricing/PricingCalculator";
import Sessions from "../component/steps/Sessions";
import "bootstrap/dist/css/bootstrap.min.css";
import HeaderOne from '../common/header/HeaderOne';
import { useParams } from 'react-router-dom';
import { options } from '../data/nav'
import Page from "../app/services/pageCopy";
const Service = () => {
    const params = useParams();
    const serviceSlug = params.slug;
    const details = options.filter((title) => title.href === serviceSlug);
    console.log(details, '>>>>>>>>>')
    return (
        <>
            <SEO title="Corporate Agency" />
            <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <Page/>
                <AboutOne />
                <CounterUpOne />
                {/* <TestimonialOne /> */}
                <div className="section bg-color-light section-padding">
                    <div className="container">
                        <SectionTitle
                            subtitle="Pricing Plan"
                            title="How We Compare Our Pricing"
                            description="Our prices are designed for students especially, they are competent and global. Choose as per your requirements."
                            textAlignment=""
                            textColor=""
                        />
                        <PricingCalculator />
                        {/* <PricingOne /> */}
                    </div>
                    <ul className="list-unstyled shape-group-3">
                        <li className="shape shape-1">
                            <img
                                src={process.env.PUBLIC_URL + "/images/others/line-1.png"}
                                alt="shape"
                            />
                        </li>
                        <li className="shape shape-2">
                            <img
                                src={process.env.PUBLIC_URL + "/images/others/bubble-4.png"}
                                alt="shape"
                            />
                        </li>
                    </ul>
                </div>
                <Sessions />
                <CtaLayoutOne />
                <FooterOne parentClass="" />
            </main>
        </>
    );
};

export default Service;