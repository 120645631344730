/* eslint-disable react/prop-types */

// eslint-disable-next-line no-unused-vars
import { Link } from "react-router-dom";
import {truncateString} from "../../utils/truncateString";

export function LatestBlogCard({
  src,
  metadescription,
  title,
  to,
  author,
  category,
  date,
}) {
  // eslint-disable-next-line no-unused-vars
  const para = truncateString(metadescription, 140);

  return (
    <div className="shadow-sm border-[1px] border-transparent hover:border-blue-700 transition-all ease-in duration-100 delay-100">
      <a href={to} className="cursor-pointer hover:no-underline">
        <div className="">
          <div color="blue-gray">
            <img
              loading="eager"
              src={src}
              alt="ui/ux review check"
              title="ui/ux review check"
              className="w-full h-full object-cover"
              width="100%"
              height="100%"
            />
          </div>
          <div className="px-6 w-full">
            <h2
              color="blue-gray"
              className="text-base font-bold font-QuickSand"
            >
              {title}
            </h2>

            <p className="pt-2.5 text-xs font-normal font-Nunito">{para}</p>

            <div className="flex justify-between w-full gap-4 py-1.5">
              <p className="text-xs font-bold font-Nunito">
                Author:{" "}
                <span className="font-medium font-QuickSand">{author}</span>
              </p>
              <p className="text-xs font-bold font-Nunito">
                Topic:{" "}
                <span className="font-medium font-QuickSand">{category}</span>
              </p>
              <span className="text-xs font-bold font-Nunito">{date}</span>
            </div>
          </div>
        </div>
      </a>
    </div>
  );
}
