import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import BreadCrumbOne from '../elements/breadcrumb/BreadCrumbOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import SEO from '../common/SEO';


const TermsOfUse = () => {

    return (
        <>
            <SEO title="Terms Of Use" />
            <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BreadCrumbOne 
                title="Terms Of Use"
                page="Terms Of Use"
                />
                <div className="section-padding privacy-policy-area">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="privacy-policy-content">
                                <p>Please Read These Terms Carefully Before Using our Service. The use of tutorpoint.in is only to be undertaken by those of legal age. Any improper usage is strictly prohibited. Once you have placed an order on this website, you have confirmed that you have read, understood, and agreed with all our Terms and Conditions. Submitting a request and/or payment implies that you are legally obligated to abide by these Terms and Conditions.</p>
                                <h4>Interpretation Of Our Terms:</h4>
                                <p>"Website" means tutorpoint.in.
                                - No permission is granted to anyone to use the content of this site for commercial purpose or to modify for any other purpose.
                                </p>
                                <h4>Consumer/Customer</h4>
                                <p>"Consumer/Customer", "You" or "Yours" refers to anyone submitting, bidding, executing an order, uploading any information, and transferring payments on this website.
                                - "Company," "We," or "Our" refers to tutorpoint.in website.
                                - Using our questions and answers service to complete tests or homework when instructed not to use outside help.
                                - Order refers to the actual request for a Service sent to our Company by the Customer. It includes particular requirements and a specification of sources to be used in writing.
                                - Support Team or Support refers to the Company's structural unit responsible for coordinating and assisting the Order process.
                                Wallet refers to the personal account of the Customer within the Company that stores Credits for the Customer. Funds added to their personal balance are done voluntarily to further compensate the price of the order(s) at their own discretion.</p>
                                <h4>Warranty and Limitation of Liability</h4>
                                <p>You will be requested to register by providing Your contact information such as name, email address, country of residence, and telephone number. Should any difficulties arise during the process of account creation, please contact our Support. In addition, should any of your contact details change over time, it is your sole responsibility to update your profile accordingly or inform Support of such changes.
                                    On receiving aquery, the price will be mutually decided. Once the payment is done we will send you solutions before the deadline. A few follow up questions are allowed to make you understand the format of the solution.
                                    The payment for the service is calculated according to the Company's Pricing and is paid in advance, as stated in the Order form once the scope of work is identified. The Company is not held responsible for Service delivery until the payment has been made in full and has been authorised.</p>
                                <h4>Charges</h4>
                                <p>The Company reserves the right to offer discount and bonus programs to Customers at its own discretion using discount code(s) the Customer can use when filling out the Order form. If the code is not provided in the corresponding section of the Order form, the discount will not be given out by the Company for that particular order.</p>
                                <h4>Severability</h4>
                                <p>If any of these terms becomes unenforceable as a whole or in part, the validity of other terms of the contract will remain unaffected.</p>
                                <h4>Amendments</h4>
                                <p>We reserve the sole right to amend terms and conditions without serving notice to you. The latest version of terms and conditions will supersede previous versions.</p>
                           
                                <h4>Termination</h4>
                                <p>We reserve the sole right to terminate the whole or part of the service agreement at any time.</p>
                                <h4>Entire Agreement</h4>
                                <p>Terms of Service made in the latest agreement between student and tutorpoint will supersede and terminate all the prior agreements.</p>
                                <h4>Subscription</h4>
                                <p>By registering or subscribing to our services. The user accepts to receive the subscription email about tutorpoint.in service updates or any other promotional offers.</p>
                           
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <CtaLayoutOne />
            <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default TermsOfUse;