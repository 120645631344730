import React from "react";
import FormOne from "../contact/FormOne";
import Countries from "../../common/carousel/Countries";
import { BsWhatsapp } from "react-icons/bs";
import { IoIosCall } from "react-icons/io";
import { LuMailPlus } from "react-icons/lu";

const AboutOne = () => {
  return (
    <>
      <section className="section section-padding-equal bg-color-light">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="about-us">
                <div className="section-heading heading-left mb-0">
                  <span className="subtitle">About Us</span>
                  <h2 className="title mb--40">
                    We help, guide &amp; develop.
                  </h2>
                  <p>
                    TalkWithGuru is an online platform that connects online
                    tutors with students across the globe who seeks academic
                    help. We help students across the globe who are stuck with
                    their subjects, by connecting them with tutors.{" "}
                  </p>
                  <p>
                    We aim to strategically connect students and tutors online.{" "}
                  </p>
                  <div className="flex flex-column gap-2">
                    <a
                      href="tel:919263457170"
                      className="flex gap-2 align-items-center text-black"
                    >
                      Click to
                      <span>
                        <IoIosCall className="text-blue-500 w-10 h-10 bg-blue-200 p-2 rounded-full" />
                      </span>
                      Call US{" "}
                    </a>
                    <a
                      href="https://wa.me/919263466958"
                      className="flex gap-2 align-items-center text-black"
                    >
                      Click for
                      <span>
                        <BsWhatsapp className="text-green-500 w-10 h-10 bg-green-200 p-2 rounded-full" />
                      </span>
                      Whatsapp Chat{" "}
                    </a>
                    <a
                      href="mailto:support@talkwithguru.com"
                      className="flex gap-2 align-items-center text-black"
                    >
                      Click to
                      <span>
                        <LuMailPlus className="text-red-500 w-10 h-10 bg-red-200 p-2 rounded-full" />
                      </span>
                      Mail Us{" "}
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-5 col-lg-6 offset-xl-1">
              <div className="contact-form-box">
                <h3 className="title">Connect with us</h3>
                <FormOne />
              </div>
            </div>
          </div>
        </div>
        <ul className="shape-group-6 list-unstyled">
          <li className="shape shape-1">
            <img
              src={process.env.PUBLIC_URL + "/images/others/bubble-7.png"}
              alt="Bubble"
            />
          </li>
          <li className="shape shape-2">
            <img
              src={process.env.PUBLIC_URL + "/images/others/line-4.png"}
              alt="line"
            />
          </li>
          <li className="shape shape-3">
            <img
              src={process.env.PUBLIC_URL + "/images/others/line-5.png"}
              alt="line"
            />
          </li>
        </ul>
      </section>
      <Countries />
    </>
  );
};

export default AboutOne;
