import React from "react";
import { Link } from "react-router-dom";
import {
  FaFacebookF,
  FaWhatsapp,
  FaTwitter,
  FaPinterestP,
  FaLinkedin,
  FaInstagram,
  FaVimeoV,
  FaDribbble,
  FaBehance,
  FaEnvelopeOpen,
} from "react-icons/fa";
import ServiceData from "../../data/service/ServiceMain.json";
import { slugify } from "../../utils";
import { BsWhatsapp, BsFacebook } from "react-icons/bs";
import "./footer.css";

const getServiceData = ServiceData;

const FooterOne = ({ parentClass }) => {
  return (
    <footer className={`footer-area ${parentClass}`}>
      <div className="container">
        <div className="footer-top">
          <div className="footer-social-link">
            <ul
              style={{
                maxWidth: "150px",
                alignItems: "center",
                margin: "auto",
              }}
              className=" list-unstyled"
            >
              <li>
                <Link
                  target="_blank"
                  to="https://www.facebook.com/profile.php?id=100089727985894&sk=about_contact_and_basic_info"
                >
                  <FaFacebookF />
                </Link>
              </li>
              {/* <li><Link to="https://twitter.com/"><FaTwitter /></Link></li>
                            <li><Link to="https://www.pinterest.com/"><FaPinterestP /></Link></li>
                            <li><Link to="https://www.linkedin.com/"><FaLinkedin /></Link></li> */}
              <li>
                <Link
                  target="_blank"
                  to="https://www.instagram.com/talk_with_guru/"
                >
                  <FaInstagram />
                </Link>
              </li>
              {/* <li><Link to="https://vimeo.com/"><FaVimeoV /></Link></li>
                            <li><Link to="https://dribbble.com/"><FaDribbble /></Link></li>
                            <li><Link to="https://www.behance.net/"><FaBehance /></Link></li> */}
            </ul>
          </div>
        </div>
        <div className="footer-main">
          <div className="row">
            <div className="col-xl-6 col-lg-5">
              <div className="footer-widget border-end">
                <div className="footer-newsletter">
                  <h2 className="title">Get in touch!</h2>
                  <p>Chat with us to know more about us!</p>
                  <div className="pricing-btn">
                    {/* <Link to="#" className="axil-btn btn-large btn-borderd">Get Started Today</Link> */}
                    <a
                      className="axil-btn btn-large btn-borderd"
                      href="https://wa.me/919263466958"
                      rel="noreferrer"
                      target="_blank"
                    >
                      What's App Me
                    </a>
                  </div>
                  {/* <form>
                                        <div className="input-group">
                                            <span className="mail-icon"><FaEnvelopeOpen /> </span>
                                            <input type="email" className="form-control" placeholder="Email address" />
                                            <button className="subscribe-btn" type="submit">Subscribe</button>
                                        </div>
                                    </form> */}
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-7">
              <div className="row">
                <div className="col-sm-6">
                  <div className="footer-widget">
                    <h6 className="widget-title">Services</h6>
                    <div className="footer-menu-link">
                      <ul className="list-unstyled">
                        {getServiceData.slice(0, 6).map((data, index) => (
                          <li key={index}>
                            <Link
                              to=""
                              // {process.env.PUBLIC_URL + `/service-details/${slugify(data.title)}`}
                            >
                              {data.title}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="footer-widget">
                    <h6 className="widget-title">Resourses</h6>
                    <div className="footer-menu-link">
                      <ul className="list-unstyled">
                        <li>
                          <Link
                            to=""
                            // {process.env.PUBLIC_URL + "/blog-grid"}
                          >
                            Blog
                          </Link>
                        </li>
                        <li>
                          {" "}
                          <Link
                            to=""
                            // {process.env.PUBLIC_URL + "/case-study"}
                          >
                            Case Studies
                          </Link>
                        </li>
                        <li>
                          <Link
                            to=""
                            // {process.env.PUBLIC_URL + "/project-grid-one"}
                          >
                            Portfolio
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="footer-widget">
                    <h6 className="widget-title">Support</h6>
                    <div className="footer-menu-link">
                      <ul className="list-unstyled">
                        <li>
                          <Link
                            to={process.env.PUBLIC_URL + "/contact"}
                            // {process.env.PUBLIC_URL + "/contact"}
                          >
                            Contact
                          </Link>
                        </li>
                        <li>
                          {" "}
                          <Link
                            to={process.env.PUBLIC_URL + "/privatepolocies"}
                            // {process.env.PUBLIC_URL + "/privacy-policy"}
                          >
                            Privacy Policy
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={process.env.PUBLIC_URL + "/termsandcondition"}
                            // {process.env.PUBLIC_URL + "/terms-use"}
                          >
                            terms & condition
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={process.env.PUBLIC_URL + "/refund"}
                            // {process.env.PUBLIC_URL + "/terms-use"}
                          >
                            Refund
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="row">
            <div className="col-md-6">
              <div className="footer-copyright">
                <span className="copyright-text">
                  © {new Date().getFullYear()}. All rights reserved by{" "}
                  <a href="">ABHAY EDUCATION PVT. LTD.</a>.
                </span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="footer-bottom-link">
                <ul className="list-unstyled">
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/privacy-policy"}>
                      Privacy Policy
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/terms-use"}>
                      Terms of Use
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div
              style={{
                position: "fixed",
                bottom: "30px",
                left: "15px",
                zIndex: "99",
              }}
            >
              {/* <a
                href="https://wa.me/919263466958"
                target="_blank"
                style={{
                  backgroundColor: "green",
                  width: "60px",
                  height: "60px",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <FaWhatsapp style={{ color: "white", fontSize: "28px" }} />
              </a> */}
            </div>
          </div>
        </div>
      </div>

      <div class="social">
        <a
          href="https://www.facebook.com/profile.php?id=100094681311127"
          target="_blank"
          rel="noreferrer"
        >
          <div class="social-btn color-telegram">
            <BsFacebook class="icons8-telegram-app" />
            <p class="order-1 google-font margin-telegram">Facebook</p>
          </div>
        </a>
        <a
          href="https://www.instagram.com/talk_with_guru/"
          target="_blank"
          rel="noreferrer"
        >
          <div class="social-btn color-instagram">
            <div class="icons8-instagram"></div>
            <p class="order-1 google-font margin-instagram">Instagram</p>
          </div>
        </a>
        <a href="https://wa.me/919263466958" target="_blank" rel="noreferrer">
          <div class="social-btn color-whatsapp">
            <BsWhatsapp class="icons8-whatsapp" />
            <p class="order-1 google-font margin-instagram">Whatsapp</p>
          </div>
        </a>
      </div>
    </footer>
  );
};

export default FooterOne;
