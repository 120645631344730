import React from 'react';
import SEO from '../common/SEO';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import FooterOne from '../common/footer/FooterOne';
import BrandOne from '../component/brand/BrandOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import ServicePropOne from '../component/service/ServicePropOne';
import SectionTitle from '../elements/section-title/SectionTitle';
import BannerFive from '../component/banner/BannerFive';
import HeaderFour from '../common/header/HeaderFour';
import AboutOne from '../component/about/AboutOne';
import CounterUpOne from '../component/counterup/CounterUpOne';
import PricingOne from '../component/pricing/PricingOne';
import PricingCalculator from "../component/pricing/PricingCalculator";
import Sessions from "../component/steps/Sessions";
import "bootstrap/dist/css/bootstrap.min.css";
import HeaderOne from '../common/header/HeaderOne';
const CorporateAgency = () => {
  return (
    <>
      <SEO title="Corporate Agency" />
      <ColorSwitcher />
      <main className="main-wrapper">
        <HeaderOne />
        {/* <HeaderFour /> */}
        <BannerFive />

        <div className="section section-padding">
          <div className="container">
            <SectionTitle
              subtitle="What We Can Do For You"
              title="Services we can <br> help you with"
              description=""
              textAlignment="heading-left mb--20 mb_md--70"
              textColor=""
            />
            <div className="row">
              <ServicePropOne
                colSize="col-lg-4"
                serviceStyle="service-style-2"
                itemShow="6"
                marginTop="yes"
              />
            </div>
          </div>
          <ul className="shape-group-7 list-unstyled">
            <li className="shape shape-1">
              <img
                src={process.env.PUBLIC_URL + "/images/others/circle-2.png"}
                alt="circle"
              />
            </li>
            <li className="shape shape-2">
              <img
                src={process.env.PUBLIC_URL + "/images/others/bubble-2.png"}
                alt="Line"
              />
            </li>
            <li className="shape shape-3">
              <img
                src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"}
                alt="Line"
              />
            </li>
          </ul>
        </div>
        <AboutOne />
        {/* <ProjectOne /> */}
        <CounterUpOne />
        {/* <TestimonialOne /> */}
        <div className="section bg-color-light section-padding">
          <div className="container">
            <SectionTitle
              subtitle="Pricing Plan"
              title="How We Compare Our Pricing"
              description="Our prices are designed for students especially, they are competent and global. Choose as per your requirements."
              textAlignment=""
              textColor=""
            />
            <PricingCalculator />
            {/* <PricingOne /> */}
          </div>
          <ul className="list-unstyled shape-group-3">
            <li className="shape shape-1">
              <img
                src={process.env.PUBLIC_URL + "/images/others/line-1.png"}
                alt="shape"
              />
            </li>
            <li className="shape shape-2">
              <img
                src={process.env.PUBLIC_URL + "/images/others/bubble-4.png"}
                alt="shape"
              />
            </li>
          </ul>
        </div>
        <Sessions />
        <BrandOne />
        {/* <BlogOne /> */}
        <CtaLayoutOne />
        <FooterOne parentClass="" />
      </main>
    </>
  );
};

export default CorporateAgency;