
import React from 'react'
import { useEffect, useState } from 'react';
import client from '../../utils/client';
import { LatestBlogCard } from './LatestBlogCard';
import { format } from "date-fns"
import ColorSwitcher from '../../elements/switcher/ColorSwitcher';
import FooterOne from '../../common/footer/FooterOne';
import HeaderOne from '../../common/header/HeaderOne';
import SEO from '../../common/SEO';

const Blogs = () => {
  const [posts, setPosts] = useState([]);
  const [categoryPosts, setCategoryPosts] = useState([]);
  useEffect(() => {
    client
      .fetch(
        `*[_type == "post"] {
          title,
          slug,
          body,
          metaDescription,
          publishedAt,
          "categories": categories[]->title,
          "name": author->name,
          mainImage {
            asset -> {
              _id,
              url
            },
          }
        } | order(publishedAt desc)`
      )
      .then((data) => {
        console.log(data, "from sanity");
        setPosts(data);
        const categoryWisePosts = {};
        data.forEach((post) => {
          post.categories.forEach((category) => {
            if (!categoryWisePosts[category]) {
              categoryWisePosts[category] = [];
            }
            categoryWisePosts[category].push(post);
          });
        });

        setCategoryPosts(categoryWisePosts);
      })
      .catch(console.error);
  }, []);
  return (
    <div>
      <SEO title='Talk With Guru Blog Posts'/>
      <ColorSwitcher />
      <HeaderOne />
      <div className="mx-auto max-w-screen-xl w-full px-6">
        <div className="py-10">
          <h1 className="text-6xl w-full justify-center flex  font-extrabold uppercase py-2 leading-[4.5rem]">
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-yellow-dark via-red-500 to-blue-darkBlue">
              Blog & Articles
            </span>
          </h1>
          <div className="mx-auto max-w-screen-xl mt-6">
            <div className="pb-10">
              <div className="border-b-violet-main border-b-[1px] mb-6 max-w-screen-lg">
                <p className="bg-[#4e4e4e] font-Inter inline-block text-gray-700 font-normal text-base text-white py-1 px-2">
                  Latest Posts
                </p>
              </div>
              {/* <p>blog {posts?.length}</p> */}

              {posts && Array.isArray(posts) && posts.length > 0 ? (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 px-10 lg:px-3">
                  {posts.map((post, index) => (
                    <LatestBlogCard
                      key={index}
                      metadescription={post.metaDescription}
                      src={post?.mainImage?.asset?.url}
                      title={post?.title}
                      author={post?.name}
                      category={post?.categories}
                      date={format(new Date(post?.publishedAt), "dd/MM/yyyy")}
                      to={`/blog/${post?.slug?.current}`}
                    />
                  ))}
                </div>
              ) : (
                <p className="pt-28 w-full text-center">No Posts Found</p>
              )}
            </div>


          </div>

          {/* categories wise */}
          {/* Display category-wise posts */}
          {Object.keys(categoryPosts).map((category, index) => (
            <div key={index} className="mx-auto max-w-screen-xl mt-6">
              <div className="pb-10">
                <div className="border-b-violet-main border-b-[1px] mb-6 max-w-screen-lg">
                  <p className="bg-[#4e4e4e] font-Inter inline-block text-gray-700 font-normal text-base text-white py-1 px-2">
                    {category}
                  </p>
                </div>
                {/* Display posts for the current category */}
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 px-10 lg:px-3">
                  {categoryPosts[category] && Array.isArray(categoryPosts[category]) && categoryPosts[category].length > 0 ? (
                    categoryPosts[category].map((post, index) => (
                      <LatestBlogCard
                        key={index}
                        metadescription={post?.metaDescription}
                        src={post.mainImage?.asset?.url}
                        title={post.title}
                        author={post.name}
                        category={post.categories}
                        date={format(new Date(post.publishedAt), "dd/MM/yyyy")}
                        to={`/blog/${post.slug?.current}`}
                      />
                    ))
                  ) : (
                    <p className="pt-28 w-full text-center">No Posts Found</p>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <FooterOne parentClass="" />
    </div>
  )
}

export default Blogs
