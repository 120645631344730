import React from "react";
import Carousel from "react-elastic-carousel";
import countryimage from "../../assets/images/canada-flag.webp";
import countryimage2 from "../../assets/images/usa-flag-300x158.webp";
import countryimage3 from "../../assets/images/great-britain-flag.webp";
import countryimage4 from "../../assets/images/australia-flag.webp";

const items = [
  { id: 1, title: "Country name", image: countryimage, subText: "Enter here" },
  { id: 2, title: "Country name", image: countryimage2, subText: "Enter here" },
  { id: 3, title: "Country name", image: countryimage3, subText: "Enter here" },
  { id: 4, title: "Country name", image: countryimage4, subText: "Enter here" },
  { id: 5, title: "Country name", image: countryimage, subText: "Enter here" },
];

const Countries = () => {
  return (
    <div className="section section-padding-equal">
      <Carousel
        itemsToShow={3}
        enableAutoPlay
        className="container"
        style={{ padding: 0 }}
      >
        {items.map((item) => (
          <div
            style={{ height: "100%", width: "100%", justifyContent: "center" }}
            className="row align-items-center"
            key={item.id}
          >
            <div
              className="row align-items-center"
              style={{ textAlign: "center" }}
            >
              {/* <a href="#" className="block"> */}
              <h5>{item.title}</h5>

              <img alt="Art" src={item.image} />

              <p>{item.subText}</p>
              {/* </a> */}
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default Countries;
